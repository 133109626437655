





























import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import type { ComputedRef, PropType, Ref } from '@nuxtjs/composition-api';
import { productGetters } from '~/utils/ct';
import {
  useI18n,
  useProductPrice,
  useProductBoxClickLocation,
} from '~/composables';
import { getProductQuantity } from '~/helpers/product/getProductStockInfo/getProductStockInfo';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import { PriceInformation } from '~/composables/useProductPrice';
import { extractLocalisedAttributes } from '~/helpers/product/extractLocalisedAttributes';
import isString from '~/helpers/dataStructure/isString';
import { getBreadcrumbsFromProduct } from '~/helpers/category/getBreadcrumbsFromProduct';
import { getEnergyRating } from '~/helpers/product/energyRating';
import ProductCardWrapper from '~/components/molecules/Product/ProductCardWrapper/ProductCardWrapper.vue';
import type { ProductCardSize } from '~/constants/productCardSize';
import { PRODUCT_CARD_SIZE } from '~/constants/productCardSize';

export default defineComponent({
  name: 'RelatedProduct',
  components: {
    ProductCardWrapper,
  },
  props: {
    product: {
      type: Object as PropType<ProductVariantExtended>,
      required: true,
    },
    priceGuaranteeUrl: {
      type: String,
      required: true,
    },
    parcelDeliveryDate: {
      type: String,
      default: '',
    },
    freightDeliveryDate: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<ProductCardSize>,
      default: PRODUCT_CARD_SIZE.RESPONSIVE,
      validator: (value: ProductCardSize) => {
        return Object.values(PRODUCT_CARD_SIZE).includes(value);
      },
    },
  },
  setup(props, { emit }) {
    const productRef: Ref<ProductVariantExtended> = ref(props.product);

    const { languageAndCountry } = useI18n();
    const { getPriceInformation } = useProductPrice();

    const productName: ComputedRef<string> = computed(() => productGetters.getName(props.product));
    const productMainImage: ComputedRef<string> = computed(() => productGetters.getCoverImage(props.product));
    const sku: ComputedRef<string> = computed(() => productGetters.getSku(props.product));
    const reviewsCount: ComputedRef<number> = computed(() => productGetters.getTotalReviews(props.product));
    const rating: ComputedRef<number> = computed(() => productGetters.getAverageRating(props.product));
    const stockCount: ComputedRef<number> = computed(() => getProductQuantity(props.product));
    const localisedAttributes: ComputedRef<ProductProcessedAttributes> = computed(() => extractLocalisedAttributes(
      props.product, [
        PRODUCT_ATTRIBUTES.TAGS,
        PRODUCT_ATTRIBUTES.BRAND,
        PRODUCT_ATTRIBUTES.IS_PACKAGE_TYPE_FREIGHT,
        PRODUCT_ATTRIBUTES.STANDARD_PRICE,
        PRODUCT_ATTRIBUTES.HIGHLIGHTS,
        PRODUCT_ATTRIBUTES.ENERGY_RATING,
        PRODUCT_ATTRIBUTES.ENERGY_RATING_FICHE,
      ],
      languageAndCountry.value,
    ));

    const dimensions = computed(() => {
      const productDimension = productGetters.getAttributes(
        props.product,
        [PRODUCT_ATTRIBUTES.NETTO_HEIGHT, PRODUCT_ATTRIBUTES.NETTO_WIDTH, PRODUCT_ATTRIBUTES.NETTO_LENGTH],
      );
      const height = productDimension[PRODUCT_ATTRIBUTES.NETTO_HEIGHT];
      const width = productDimension[PRODUCT_ATTRIBUTES.NETTO_WIDTH];
      const length = productDimension[PRODUCT_ATTRIBUTES.NETTO_LENGTH];
      const hasDimensions = !!height && !!width && !!length;
      return {
        height,
        width,
        length,
        hasDimensions,
      };
    });

    const energyRating = computed(() => {
      return getEnergyRating({ product: props.product, languageAndCountry: languageAndCountry.value });
    });
    const priceInfo: ComputedRef<PriceInformation> =
      computed(() => getPriceInformation(productRef, localisedAttributes));

    const { productBoxClickLocation } = useProductBoxClickLocation();

    const relatedProductClicked = () => {
      emit('related-product-clicked', sku.value);
    };

    const releaseDate = computed(() =>
      productGetters.getAttributes(props.product, [PRODUCT_ATTRIBUTES.RELEASE_DATE])?.releaseDate,
    );
    const releaseDateString = computed(() => isString(releaseDate.value) ? releaseDate.value : '');

    const categories = computed(() => {
      const breadcrumbs = getBreadcrumbsFromProduct(props.product);
      const categoryNames = breadcrumbs.slice(1).map(breadcrumb => breadcrumb.name);
      return categoryNames.join(' > ');
    });

    const productLink = computed(() => `/${props.product?._slug}`);

    return {
      PRODUCT_ATTRIBUTES,
      productName,
      sku,
      reviewsCount,
      rating,
      stockCount,
      localisedAttributes,
      priceInfo,
      dimensions,
      productMainImage,
      releaseDateString,
      categories,
      productBoxClickLocation,
      productLink,
      relatedProductClicked,
      energyRating,
      getEnergyRating,
    };
  },
});
